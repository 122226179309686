import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
import { useTranslation } from "react-i18next";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import standard from "images/Standard.png";
import premium from "images/Premium.png";
import enterprise from "images/Enterprise.png";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.span`uppercase text-primary-500`;
export default () => {
  const { t } = useTranslation();
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        heading={"UseAllFood"}
        subheading={<Subheading>{t("about_us.subheading1")}</Subheading>}
        description={
          <>
            <p>{t("about_us.description1.p1")}</p>
            <p>{t("about_us.description1.p2")} </p>
            <p>{t("about_us.description1.p3")}</p>
          </>
        }
        buttonRounded={false}
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>{t("about_us.subheading2")}</Subheading>}
        heading={t("about_us.heading2")}
        description={
          <>
            <p>{t("about_us.description2.p1")}</p>
            <p>{t("about_us.description2.p2")} </p>
            <p>{t("about_us.description2.p3")}</p>
          </>
        }
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        shapeTrue={true}
      />

      <Features
        subheading={<Subheading>UseAllFood</Subheading>}
        heading={t("about_us.heading3")}
        // description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: standard,
            title: `${t("about_us.card1.title")}`,
            description: `${t("about_us.card1.description")}`,
          },
          {
            imageSrc: premium,
            title: `${t("about_us.card2.title")}`,
            description: `${t("about_us.card2.description")}`,
          },
          {
            imageSrc: enterprise,
            title: `${t("about_us.card3.title")}`,
            description: `${t("about_us.card3.description")}`,
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
