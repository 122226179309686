import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import { useParams } from 'react-router-dom';
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logoUseAllFood.jpg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram.svg";

import { useTranslation } from "react-i18next";
import Language from "components/misc/Language";
import organizationImg from "../../images/organization.png";

const Container = tw(ContainerBase)` bg-gray-200 text-gray-700 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const SmallContainer = tw.div`w-full p-5 bg-gray-100  flex flex-row justify-center content-center`;
const Image = tw.img`w-24  inline-block`;
const Paragraph = tw.p`text-sm font-medium text-gray-600 w-1/2 flex flex-row self-center`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-300 hocus:border-primary-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-700 hover:text-primary-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  // const { name } = useParams()
  const { t } = useTranslation();

  return (
    <Container>
      <SmallContainer>
        <Paragraph>{t("footer.description")}</Paragraph>
        <Image src={organizationImg} />
      </SmallContainer>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>UseAllFood</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="/#/AboutUs">{t("footer.about")}</Link>
            <Link href="/#/ContactUs">{t("footer.contact")}</Link>
            <Link href="/#/PrivacyPolicy">{t("footer.privacy_policy")}</Link>
            <Link href="/#/TermsOfService">{t("footer.terms_of_service")}</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.facebook.com/Use_all_food-111311057712068"
              target="_blank"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/UseAllFood" target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/in/useallfood-project-b43ba5209"
              target="_blank"
            >
              <LinkedinIcon />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/useallfood/"
              target="_blank"
            >
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
          <Language mt={true} />
          <CopyrightText>
            &copy; Copyright 2021, UseAllFood Inc. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
