import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationSR from "./sr/sr-translation.json";
import translationEN from "./en/en-translation.json";

const resources = {
  sr: {
    translation: translationSR,
  },
  en: {
    translation: translationEN,
  },
};

const curLang = localStorage.getItem("curLang");

i18n.use(initReactI18next).init({
  resources,
  lng: curLang || "sr",
  fallbackLng: "sr",
  interpolation: { escapeValue: false },
});

export default i18n;
