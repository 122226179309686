import React from "react";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import TabGrid from "components/cards/TabCardGrid.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { useTranslation, Trans } from "react-i18next";

import standard from "images/Standard.png";
import premium from "images/Premium.png";
import enterprise from "images/Enterprise.png";
import landingImg1 from "../images/landingImg1.png";
import landingImg2 from "../images/landingImg2.png";
import organizationImg from "../images/organization.png";
export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const SmallText = tw.h3`ml-2 mb-4 text-3xl font-black tracking-wider`;
  const HighlightedTextGreen = tw.span`bg-green-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextYellow = tw.span`bg-yellow-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextRed = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-4`;
  const imageCss = tw`rounded-4xl`;
  const Image = tw.img`w-16`;
  const { t } = useTranslation();
  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            {t("landing.heading1")}
            <br />
            <HighlightedTextGreen>UseAllFood</HighlightedTextGreen>
          </>
        }
        description={t("landing.description0")}
        imageSrc={landingImg2}
        imageCss={imageCss}
        imageDecoratorBlob={true}
       // primaryButtonText={t("landing.buttonText")}
        //primaryButtonUrl="#/Contests"
      //  watchVideoButtonText="Meet The Chefs"
      />
      <MainFeature
        // subheading={<Subheading>Established Since 2014</Subheading>}
        heading={
          <>
            {t("landing.heading2")}
            <br />
            <HighlightedTextYellow>Use your food smart</HighlightedTextYellow>
          </>
        }
        description={
          <Description>
            <Trans components={{ break: <br /> }}>landing.description2</Trans>
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonUrl={null}
        imageSrc={landingImg1}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        shapeTrue={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      {/* <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>menu.</HighlightedText>
          </>
        }
      /> */}
      <Features
        heading={
          <>
            <Trans components={{ highlightRed: <HighlightedTextRed /> }}>
              landing.heading3
            </Trans>
          </>
        }
        cards={[
          {
            imageSrc: standard,
            title: "UseAllFood Standard",
            description: `${t("landing.card1")}`,
            url: "https://google.com",
          },
          {
            imageSrc: premium,
            title: "UseAllFood Premium",
            description: `${t("landing.card2")}`,
            url: "https://timerse.com",
          },
          {
            imageSrc: enterprise,
            title: "UseAllFood Enterprise",
            description: `${t("landing.card3")}`,
            url: "https://reddit.com",
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      {/* <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        statistics={[
          {
            key: "Orders",
            value: "94000+",
          },
          {
            key: "Customers",
            value: "11000+"
          },
          {
            key: "Chefs",
            value: "1500+"
          }
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}
      <DownloadApp
        text={
          <>
            <SmallText>{t("landing.download")}</SmallText>
            <HighlightedTextInverse>UseAllFood App</HighlightedTextInverse>
          </>
        }
      />
      <Footer />
    </AnimationRevealPage>
  );
};
