import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import { useTranslation, Trans } from "react-i18next";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800 `}
  p {
    ${tw`mt-3 leading-normal`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({}) => {
  const { t } = useTranslation();
  const headingText = t("terms_of_service.heading-1");
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <Trans components={{ parag: <p /> }}>
              terms_of_service.terms-1
            </Trans>
            <Trans components={{ heading2: <h2 /> }}>
              terms_of_service.heading-2
            </Trans>
            <Trans components={{ parag: <p /> }}>
              terms_of_service.terms-2
            </Trans>
            <Trans components={{ heading2: <h2 /> }}>
              terms_of_service.heading-3
            </Trans>
            <Trans components={{ parag: <p /> }}>
              terms_of_service.terms-3
            </Trans>
            <Trans components={{ heading2: <h2 /> }}>
              terms_of_service.heading-4
            </Trans>

            <Trans components={{ parag: <p /> }}>
              terms_of_service.terms-4
            </Trans>
            <Trans components={{ heading2: <h2 /> }}>
              terms_of_service.heading-5
            </Trans>

            <Trans components={{ parag: <p /> }}>
              terms_of_service.terms-5
            </Trans>
            <Trans components={{ heading2: <h2 /> }}>
              terms_of_service.heading-6
            </Trans>
            <Trans components={{ parag: <p /> }}>
              terms_of_service.terms-6
            </Trans>
            <Trans components={{ heading2: <h2 /> }}>
              terms_of_service.heading-7
            </Trans>
            <Trans components={{ parag: <p /> }}>
              terms_of_service.terms-7
            </Trans>
            <Trans components={{ heading2: <h2 /> }}>
              terms_of_service.heading-8
            </Trans>
            <Trans components={{ parag: <p /> }}>
              terms_of_service.terms-8
            </Trans>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
