import React from 'react';
import { useParams } from 'react-router-dom';

import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";

import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
import ContestsPage from 'pages/Contests.js';

import ContactUsPageImageSrc from "images/demo/ContactUsPage.jpeg";
import TermsOfServicePageImageSrc from "images/demo/TermsOfServicePage.jpeg";
import PrivacyPolicyPageImageSrc from "images/demo/PrivacyPolicyPage.jpeg";
import AboutUsPageImageSrc from "images/demo/AboutUsPage.jpeg";
export const components = {
  AboutUs: {
    component: AboutUsPage,
    url: `/#/AboutUs`,
    imageSrc: AboutUsPageImageSrc,
  },
  ContactUs: {
    component: ContactUsPage,
    url: `/#/ContactUs`,
    imageSrc: ContactUsPageImageSrc,
  },
  Contests: {
    component: ContestsPage,
    url: `/#/Contests`,
    // imageSrc: ContactUsPageImageSrc,
  },
  TermsOfService: {
    component: TermsOfServicePage,
    url: `/#/TermsOfService`,
    imageSrc: TermsOfServicePageImageSrc,
  },
  PrivacyPolicy: {
    component: PrivacyPolicyPage,
    url: `/#/PrivacyPolicy`,
    imageSrc: PrivacyPolicyPageImageSrc,
  }

}

export default () => {
  const { name } = useParams()
  try {
    let Component = null;
    Component = components[name].component
    if (Component)
      return <Component />

    throw new Error("Component Not Found")
  }
  catch (e) {
    console.log(e)
    return <div>Error: Component Not Found</div>
  }
}
