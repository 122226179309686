import React from "react";
import { changeLanguage } from "../../locales/languageChange";
import styled from "styled-components";
import tw from "twin.macro";

export const LangContainer = styled.div`
  display: inline-flex;
  margin-left: 1rem;
  @media (max-width: 1024px) {
    margin-left: 0;
    & a {
      margin: 0.2rem;
    }
  }
`;

export const Lang = tw.a`
text-lg my-2 lg:text-sm lg:mx-1 lg:my-0
font-normal tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 cursor-pointer 
`;
export const LangLine = tw.a`
text-lg my-2 lg:text-sm lg:mx-1 lg:my-0
font-normal tracking-wide cursor-default
`;

const Language = (props) => {
  const curLang = localStorage.getItem("curLang");

  return (
    <LangContainer style={{ marginTop: props.mt && "2.5rem" }}>
      <Lang
        onClick={changeLanguage("sr")}
        style={{
          fontWeight: (!curLang || curLang === "sr") && "bold",
        }}
      >
        SR
      </Lang>
      <LangLine>|</LangLine>
      <Lang
        onClick={changeLanguage("en")}
        style={{ fontWeight: curLang === "en" && "bold" }}
      >
        EN
      </Lang>
    </LangContainer>
  );
};

export default Language;
