import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { useTranslation, Trans } from "react-i18next";

const Heading = tw(
  HeadingBase
)`text-center lg:text-left text-primary-900 leading-snug`;
const HeadingRow = tw.div`flex`;
//const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-normal`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ol {
    ${tw`list-decimal list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({}) => {
  const { t } = useTranslation();
  const headingText = t("contests.heading");
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <Trans components={{ heading1: <h1 /> }}>contests.heading-1</Trans>
            <Trans
              components={{
                parag: <p />,
                bold: <b tw="text-primary-500" />,
              }}
            >
              contests.article-1
            </Trans>
            <Trans components={{ heading1: <h1 /> }}>contests.heading-2</Trans>
            <Trans
              components={{
                parag: <p />,
                bold: <b tw="text-primary-500" />,
              }}
            >
              contests.article-2
            </Trans>
            <Trans components={{ heading1: <h1 /> }}>contests.heading-3</Trans>
            <Trans
              components={{
                parag: <p />,
              }}
            >
              contests.article-3
            </Trans>
            <Trans components={{ heading1: <h1 /> }}>contests.heading-4</Trans>
            <Trans
              components={{
                parag: <p />,
                bold: <b tw="text-primary-500" />,
                bold2: <b />,
                olist: <ol />,
                list: <li />,
              }}
            >
              contests.article-4
            </Trans>
            <Trans components={{ heading1: <h1 /> }}>contests.heading-5</Trans>
            <Trans
              components={{
                parag: <p />,
                bold2: <b />,
                olist: <ol />,
                list: <li />,
              }}
            >
              contests.article-5
            </Trans>
            <Trans components={{ heading1: <h1 /> }}>contests.heading-6</Trans>
            <Trans components={{ parag: <p /> }}>contests.article-6</Trans>
            <Trans components={{ heading1: <h1 /> }}>contests.heading-7</Trans>
            <Trans
              components={{
                parag: <p />,
                bold: <b tw="text-primary-500" />,
                ulist: <ul />,
                list: <li />,
                facebook: (
                  <a
                    href="https://www.facebook.com/legal/terms"
                    target="_blank"
                  />
                ),
                instagram: (
                  <a
                    href="https://help.instagram.com/581066165581870"
                    target="_blank"
                  />
                ),
                twitter: (
                  <a href="https://twitter.com/en/tos" target="_blank" />
                ),
                linkedIn: (
                  <a
                    href="https://www.linkedin.com/legal/user-agreement"
                    target="_blank"
                  />
                ),
              }}
            >
              contests.article-7
            </Trans>
            <Trans components={{ heading1: <h1 /> }}>contests.heading-8</Trans>
            <Trans components={{ parag: <p /> }}>contests.article-8</Trans>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
